exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-propos-js": () => import("./../../../src/pages/a-propos.js" /* webpackChunkName: "component---src-pages-a-propos-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-le-blog-js": () => import("./../../../src/pages/le-blog.js" /* webpackChunkName: "component---src-pages-le-blog-js" */),
  "component---src-pages-les-biens-js": () => import("./../../../src/pages/les-biens.js" /* webpackChunkName: "component---src-pages-les-biens-js" */),
  "component---src-pages-les-biens-sanity-biens-slug-current-js": () => import("./../../../src/pages/les-biens/{sanityBiens.slug__current}.js" /* webpackChunkName: "component---src-pages-les-biens-sanity-biens-slug-current-js" */),
  "component---src-pages-nos-offres-cima-js": () => import("./../../../src/pages/nos-offres/cima.js" /* webpackChunkName: "component---src-pages-nos-offres-cima-js" */),
  "component---src-pages-nos-offres-js": () => import("./../../../src/pages/nos-offres.js" /* webpackChunkName: "component---src-pages-nos-offres-js" */),
  "component---src-pages-nos-offres-piuma-js": () => import("./../../../src/pages/nos-offres/piuma.js" /* webpackChunkName: "component---src-pages-nos-offres-piuma-js" */)
}

